import axios from './axios'





export function getSalary() {
    return axios({
        url: 'api/v1/salary',

    })
}