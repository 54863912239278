import axios from './axios'

//修改身份证号码
export function idCard(name, id_card) {
    return axios({
        url: 'api/v1/user/idCard',
        method: 'POST',
        params: {
            name,
            id_card
        }
    })
}
//验证当前的手机号码 verificationMobile

export function verificationMobile(mobile, verification_key, verification_code) {
    return axios({
        url: 'api/v1/user/verificationMobile',
        method: 'POST',
        params: {
            mobile,
            verification_key,
            verification_code
        }
    })
}

//绑定手机号码 mobile

export function mobile(mobile, verification_key, verification_code) {
    return axios({
        url: 'api/v1/user/mobile',
        method: 'PATCH',
        params: {
            mobile,
            verification_key,
            verification_code
        }
    })
}


//更改身份证号码

export function idCard2(mobile, id_card, verification_key, verification_code) {
    return axios({
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: 'api/v1/user/idCard',
        method: 'PATCH',
        params: {
            mobile,
            id_card,
            verification_key,
            verification_code

        }
    })
}

//修改图像的值  http://{{host}}/api/v1/images





export function upLoadImag(formData) {
    return axios({
        url: 'api/v1/images',
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    })
}

// http://{{host}}/api/v1/user




// c_name
export function userForAvator(avatar_image_id) {
    return axios({
        url: 'api/v1/user',
        method: 'PATCH',
        params: {
            avatar_image_id,
        }
    })
}

export function userForContact(contact) {

    return axios({
        url: 'api/v1/user',
        method: 'PATCH',

        params: {
            contact
        }
    })
}


export function userForName(c_name) {
    return axios({
        url: 'api/v1/user',
        method: 'PATCH',

        params: {
            c_name,

        }
    })
}


// export function userForAvator(c_name, avatar_image_id, contact) {
//     return axios({
//         url: 'api/v1/user',
//         method: 'PATCH',

//         params: {
//             c_name,
//             avatar_image_id,
//             contact
//         }
//     })
// }





// export function upLoadImag(image, type) {
//     return axios({
//         url: 'api/v1/images',
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded'
//         },
//         params: {
//             image,
//             type

//         }
//     })
// }




// export function idCard2(mobile, id_card, verification_key, verification_code) {
//     return axios({
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded'
//         },
//         url: 'api/v1/user/idCard',
//         method: 'PATCH',
//         params: {
//             mobile,
//             id_card,
//             verification_key,
//             verification_code
//         }
//     })
// }