<template>
  <div>
    <nav-bar-back class="detail-nav">
      <div class="title" slot="title">
        <div slot="center">薪资详情</div>
      </div>
    </nav-bar-back>
    <div v-if="salary">
      <div>
        <div class="header">
        <p>{{year}}年{{month}}月实发工资</p>
        <p>{{total_salary}}元</p>
      </div>
      <van-cell-group>
        <van-cell v-for="(item, key, index) in salary" :key="index" :title="key" :value="item"></van-cell>
      </van-cell-group>
      </div>
    </div>
    <!-- <div v-else-if="res.salary">
      <div>
        <div class="header">
        <p>{{res.salary.year}}年{{res.salary.month}}月实发工资</p>
        <p>{{res.salary.total_salary}}元</p>
      </div>
      <van-cell-group>
        <van-cell v-for="(item, key, index) in res.salary.content" :key="index" :title="key" :value="item"></van-cell>
      </van-cell-group>
      </div>
    </div> -->
    <div v-else class="detail">暂无薪资详情</div>
  </div>
</template>

<script>
import { Cell, CellGroup, Toast, Dialog, Field } from "vant";
import NavBarBack from "common/navbar/NavBarBack";
import {
  upLoadImag,
  userForAvator,
  userForContact,
  userForName,
} from "network/identify";
import { getSalary } from "network/wage";

// upLoadImag
export default {
  components: {
    NavBarBack,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  data() {
    return {
      wage: "0.00",
      time: "0",
      price: "0",
      wageAdd: "0",
      account: "0.00",
      shebao: "0",
      shuidian: "0",
      jiezhi: "0",
      myWage: "0.00",
      salary: null,
      year:"",
      month: "",
      total_salary: "",
    };
  },
  created() {
    getSalary().then((res) => {
      this.salary = res.columns;
      this.year = res.time.year;
      this.month = res.time.month;
      this.total_salary = res.time.total_salary;
      console.log(this.salary);
    });

    this.name = this.$route.query.name;
    this.phone = this.$route.query.phone;
  },
};
</script>

<style scoped>
.detail {
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 15px;
  margin: 10px auto 0px auto;
  border-radius: 5px;
  width: 90%;

  background-color: #fff;
}
.header {
  height: 105px;
  background-color: #fff;
  margin-top: 1px;
  position: relative;
}
.header :first-child {
  position: absolute;
  left: 16px;
  /* margin-top:  */
  top: 14px;

  font-size: 14px;
  /* top: 20px; */
}
.header :last-child {
  /* margin-top: 3px; */
  line-height: 105px;
  text-align: center;
  /* margin-top: 3px; */
  font-size: 24px;
  background-color: white;
}
.itemTitle {
  /* padding: 3px; */
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 500;
}
.itemTitle :first-child {
  font-size: 17px;
}
.itemTitle :last-child {
  font-size: 17px;
  color: rgb(243, 70, 41);
}

.phoneInput {
  width: 80%;
  /* padding: 30px; */
  /* margin: 30px; */
  margin-top: 20px;
  margin-bottom: 20px;

  margin-left: auto;
  margin-right: auto;
  background-color: #eee;

  border: none;
}

.item {
  display: flex;
  justify-content: space-between;
}
.cell {
  margin-top: 15px;
}
</style>





